import React from 'react';

function About() {
  return (
    <section className="about" data-scroll-index="2">
      <div className="container section-padding bord-thin-top">
        <div className="row md-marg">
          <div className="col-lg-6">
            <div className="cont md-mb50">
              <h6 className="sub-title main-color mb-15">Who We Are</h6>
              <h5>
              At Devnance, we drive business transformation. With over a decade of software development expertise, we harness cutting-edge AI technology to boost growth and innovation. Our team of experts crafts scalable, high-quality solutions that exceed expectations and help businesses move forward. Together, let's turn your vision into reality.
              </h5>
              <div className="d-flex align-items-end mt-100">
                <div>
                <a className="d-flex align-items-end" href="#0" data-scroll-nav="2">
                    <span className="sub-title nowrap">About Us</span>
                    <span className="fz-70 line-height-45 ti-arrow-top-right"></span>
                  </a>
                </div>
                <div className="ml-80">
                  <p>
                  Devnance is a software house specializing in cutting-edge solutions, including AI chatbot development services. Our dedicated team delivers custom software tailored to your unique business needs, helping you thrive in the digital era.{' '}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="img-vid">
              <img src="/dark/assets/imgs/intro/2.jpg" alt="AI Chatbot Service" />
              <div className="curv-butn main-bg">
                <a href="https://youtu.be/IMusU0KvWa0" className="vid">
                  <div className="icon">
                    <i className="fas fa-play"></i>
                  </div>
                </a>
                <div className="shap-left-top">
                  <svg
                    viewBox="0 0 11 11"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-11 h-11"
                  >
                    <path
                      d="M11 1.54972e-06L0 0L2.38419e-07 11C1.65973e-07 4.92487 4.92487 1.62217e-06 11 1.54972e-06Z"
                      fill="#1a1a1a"
                    ></path>
                  </svg>
                </div>
                <div className="shap-right-bottom">
                  <svg
                    viewBox="0 0 11 11"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-11 h-11"
                  >
                    <path
                      d="M11 1.54972e-06L0 0L2.38419e-07 11C1.65973e-07 4.92487 4.92487 1.62217e-06 11 1.54972e-06Z"
                      fill="#1a1a1a"
                    ></path>
                  </svg>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default About;
