import React from 'react';  
  
function Marquee() {  
  return (  
    <section>  
      <div className="main-marq lrg o-hidden">  
        <div className="slide-har st1">  
          <div className="box">  
            <div className="item">  
              <h4 className="d-flex align-items-center">  
                <span>Ecommerce Development</span>  
                <span className="fz-50 ml-50 stroke icon">*</span>  
              </h4>  
            </div>  
            <div className="item">  
              <h4 className="d-flex align-items-center">  
                <span>Custom Software Development</span>  
                <span className="fz-50 ml-50 stroke icon">*</span>  
              </h4>  
            </div>  
            <div className="item">  
              <h4 className="d-flex align-items-center">  
                <span>Artificial Intelligence and Machine Learning</span>  
                <span className="fz-50 ml-50 stroke icon">*</span>  
              </h4>  
            </div>  
            <div className="item">  
              <h4 className="d-flex align-items-center">  
                <span>Mobile App Development</span>  
                <span className="fz-50 ml-50 stroke icon">*</span>  
              </h4>  
            </div>  
            <div className="item">  
              <h4 className="d-flex align-items-center">  
                <span>Cybersecurity Solutions</span>  
                <span className="fz-50 ml-50 stroke icon">*</span>  
              </h4>  
            </div>  
          </div>  
          <div className="box">  
            <div className="item">  
              <h4 className="d-flex align-items-center">  
                <span>Amazing Design</span>  
                <span className="fz-50 ml-50 stroke icon">*</span>  
              </h4>  
            </div>  
            <div className="item">  
              <h4 className="d-flex align-items-center">  
                <span>Web Development</span>  
                <span className="fz-50 ml-50 stroke icon">*</span>  
              </h4>  
            </div>  
            <div className="item">  
              <h4 className="d-flex align-items-center">  
                <span>SEO</span>  
                <span className="fz-50 ml-50 stroke icon">*</span>  
              </h4>  
            </div>  
          </div>  
        </div>  
      </div>  
    </section>  
  );  
}  
  
export default Marquee;  