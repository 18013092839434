import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomeStartupOnePage from './pages/dark/home-startup-onepage';
import { Analytics } from '@vercel/analytics/react';
import { SpeedInsights } from "@vercel/speed-insights/react";
import ReactGA from 'react-ga';
import { useEffect } from 'react';

// Initialize Google Analytics on app load
const trackingId = 'G-TXDC1SB2DM'; // Replace with your actual tracking ID

ReactGA.initialize(trackingId);

function App() {
  useEffect(() => {
    // Track pageviews on route changes
    const handleRouteChange = (to) => {
      ReactGA.set({ page: to.pathname });
      ReactGA.pageview(to.pathname);
    };

    // Subscribe to route changes
    const unsubscribe = window.addEventListener('popstate', handleRouteChange);

    // Clean up on component unmount
    return () => unsubscribe();
  }, []);

  return (
    <Router>
      <Routes>
        <Route path="/" element={<Layout><HomeStartupOnePage /></Layout>} />
        {/* Add more routes here */}
      </Routes>
    </Router>
  );
}

function Layout({ children }) {
  return (
    <>
      {children}
      <Analytics />
      <SpeedInsights />
    </>
  );
}

export default App;